<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img src="@/assets/icons/logo.png" style="max-height: 72px; margin: 12px;" class="c-sidebar-brand-full"/>
      <img src="@/assets/icons/icon.png" style="margin: 10px; max-width: 48px;" class="c-sidebar-brand-minimized"/>
      <!-- <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      /> -->
    </CSidebarBrand>
   
    <CRenderFunction flat :content-to-render="computedSidebar"/>
   
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import http from '@/utils/http-common';


export  default {
  name: 'TheSidebar',
  nav,
  data() {
    return {
      role: localStorage.getItem('role'),
      id: +localStorage.getItem('uid'),
      site: ''
    };
  },
  methods: {
    getUser() {
      let self = this
			return http.get('/users/' + this.id)
				.then(function (response) {
					// console.log(response.data.user.site_id)
          return self.site = response.data.user.site_id
        }).catch(function (error) {
					console.log(error);
				});
		},
  },
  mounted(){
    this.getUser()
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    },
    sideMenus() {
      //sidebar items are not shown until role is known

      if (this.role === '') {
        return [];
      }
      
      if(this.site == 1){
        nav[1]['to'] = '/ladctbs'
      }else if(this.site == 2){
        nav[1]['to'] = '/ladctkn'
      }else if(this.site == 3){
        nav[1]['to'] = '/ladcjah'
      }

      return nav.filter(item => {
        return !item.roles || item.roles.includes(this.role);
      });
    },
    computedSidebar() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.sideMenus
        }
      ];
    }
  },
};

</script>

<style scoped>
.c-sidebar-brand {
  background: #fff;
}
</style>
