<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
      <img src="@/assets/icons/logo.png" style="max-height: 72px;" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          <strong>Selamat Datang, {{user.user.name}} </strong> &nbsp;- {{roles[0].name}}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink v-if="notification > 0 && role <= 5 && role !== 4">
          <!-- <CLink to="/approval/visit-request">
            <CIcon size="lg" name="cil-bell" class="animate__animated animate__swing animate__infinite" />
            <CBadge color="danger" class="animate__animated animate__flash animate__infinite">{{notification}}</CBadge>
          </CLink> -->
          <CDropdown
            inNav
            class="c-header-nav-items"
            placement="bottom-end"
            add-menu-classes="pt-0"
          >
            <template #toggler>
              <div>
                <CIcon size="lg" name="cil-bell" class="animate__animated animate__swing animate__infinite" />
                <CBadge color="danger" class="animate__animated animate__flash animate__infinite">{{notification}}</CBadge>
              </div>
            </template>
            <CDropdownHeader tag="div" color="danger" router-link="/approval/visit-request">
              <strong style="color: #fff;">You Have {{notification}} Pending Approval</strong>
            </CDropdownHeader>
            <CDropdownItem v-for="item in lists.slice(0, 5)" :key="item.id" style="border-bottom: 1px solid #ececee;">
              <div class="media" @click="toPage(item.id)">
                <img class="c-avatar mr-3" :src="'https://ui-avatars.com/api/?name='+ item.company_name + '&background=e55353&color=fff'" alt="IMG">
                <div class="media-body">
                  <h6 class="mt-0">{{item.company_name}} &#183; <small>{{item.activity_name}}</small></h6>
                  <p>
                    <small>{{item.site_name}} &#183; {{item.room_name}}
                    <br>{{item.start_date | formatDate}} {{item.start_date | formatTime}} - {{item.end_date | formatTime}}</small>
                  </p>
                </div>
              </div>
            </CDropdownItem>
            <p class="mt-3 ml-3">
              <router-link to="/approval/visit-request">View all request</router-link>
            </p>
          </CDropdown>
        </CHeaderNavLink>
        <CHeaderNavLink v-if="notification === 0">
          <CIcon size="lg" name="cil-bell" color="secondary" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
      <CBreadcrumb :items="links" style="margin-top: -28px;"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import http from '@/utils/http-common';
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import { mapState } from 'vuex'

export default {
  name: 'TheHeader',
  data () {
    return {
      role: +localStorage.getItem('role'),
      pending: 0
    }
  },
  components: {
    TheHeaderDropdownAccnt
  },
  computed: {
    ...mapState(['user', 'roles', 'notification', 'lists'])
  },
  methods: {
    toast(message) {
      this.$toast.open({
        message: message,
        type: "info", // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 2000,
        dismissible: true
      })
    },
    goTo(count) {
      // console.log(count)
      this.$router.push({ path: '/approval/visit-request' });
    },
    toPage(id) {
      this.$router.push({ path: '/approval/visit-request/' + id });
    },
    getData() {
      let self = this;
      self.$store.dispatch('notified')
				.then(() => {
					// self.toast('Mencari Status Pending..')
				})
				.catch(function (error) {
        	console.log(error);
      	});

      // run after 1 minutes  
      setInterval(function() {
        // your code goes here...
        // console.log('get fresh visits')
        self.$store.dispatch('notified')
				.then(() => {
					// self.toast('Mencari Status Pending..')
				})
				.catch(function (error) {
        	console.log(error);
      	});
      }, 180 * 1000); // every 3 minutes
    },
  },
  mounted () {
    this.getData();
  }
}
</script>
<style scoped>
CDropdownItem {
  min-width: 208px;
}
</style>
