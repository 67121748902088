export default [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    roles: ['1', '2']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Big Screen',
    to: '',
    target: '_blank',
    icon: 'cil-speedometer',
    roles: ['5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard TB Simatupang',
    to: '/ladctbs',
    target: '_blank',
    icon: 'cil-speedometer',
    roles: ['1', '2', '3']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard Taman Tekno',
    to: '/ladctkn',
    target: '_blank',
    icon: 'cil-speedometer',
    roles: ['1', '2', '3']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard Jatiluhur',
    to: '/ladcjah',
    target: '_blank',
    icon: 'cil-speedometer',
    roles: ['1', '2', '3']
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['ANALYTICS'],
    roles: ['1', '2', '3', '5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Trend per Data Center',
    to: '/reports/trend-site',
    icon: 'cil-calendar',
    roles: ['1', '2', '3', '5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Trend per Room',
    to: '/reports/trend-room',
    icon: 'cil-calendar',
    roles: ['1', '2', '3','5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Trend Total Visitor 3 DC',
    to: '/reports/trend-visitor',
    icon: 'cil-calendar',
    roles: ['1', '2']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Trend Peak Hour Statistic',
    to: '/reports/trend-peak-hour-statistic',
    icon: 'cil-calendar',
    roles: ['1', '2', '3','5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Report All Visitor',
    to: '/reports/list-visitor',
    icon: 'cil-calendar',
    roles: ['1', '2', '3','5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Report All Authorized Persons',
    to: '/reports/list-authorized-person',
    icon: 'cil-calendar',
    roles: ['1', '2', '3','5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Report All NDA Status',
    to: '/reports/list-nda',
    icon: 'cil-calendar',
    roles: ['1', '2', '3','5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Report Visit Card Usage List',
    to: '/reports/list-card-visit-usage',
    icon: 'cil-calendar',
    roles: ['1', '2', '3','5']
  }, 
  {
    _name: 'CSidebarNavItem',
    name: 'Report All BAST',
    to: '/reports/list-bast',
    icon: 'cil-calendar',
    roles: ['1', '2', '3','5']
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['ACCOUNT'],
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Profile',
    to: '/profile',
    icon: 'cil-user',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['VISITOR'],
    roles: ['1', '2', '3', '4', '5', '6', '7', '8']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Visit Request',
    to: '/visitors/new-request',
    icon: 'cil-calendar',
    roles: ['1', '2', '3', '4', '5', '6', '7', '8']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Visit Plan',
    to: '/approval/visit-request',
    icon: 'cil-calendar',
    roles: ['6', '7', '8']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Requests Approval',
    to: '/approval/visit-request',
    icon: 'cil-task',
    roles: ['4']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Visitors',
    to: '/settings/users',
    icon: 'cil-user',
    roles: ['4']
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['RECEPTIONIST'],
    roles: ['2', '5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Requests Approval',
    to: '/approval/visit-request',
    icon: 'cil-task',
    roles: ['5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Scan QRCode',
    to: '/approval/scan',
    icon: 'cil-task',
    roles: ['2', '5']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Guest NDA Sign',
    to: '/settings/users',
    icon: 'cil-task',
    roles: ['1', '3', '5']
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['OPERATOR'],
    roles: ['1', '2', '3']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Requests Approval',
    to: '/approval/visit-request',
    icon: 'cil-task',
    roles: ['1', '2', '3'],
    /* badge: {
      class: 'notif animate__animated animate__flash animate__infinite',
      color: 'danger',
      text: 'NEW'
    } */
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Scan QRCode',
    to: '/approval/scan',
    icon: 'cil-task',
    roles: ['1', '3']
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['SUPERVISOR'],
    roles: ['2']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Companies',
    to: '/settings/companies',
    icon: 'cil-sitemap',
    roles: ['2']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Users',
    to: '/settings/users',
    icon: 'cil-sitemap',
    roles: ['2']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Sites',
    to: '/settings/sites',
    icon: 'cil-sitemap',
    roles: ['2']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Rooms',
    to: '/settings/rooms',
    icon: 'cil-sitemap',
    roles: ['2']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Access Cards',
    to: '/settings/cards',
    icon: 'cil-sitemap',
    roles: ['2']
  },
  
  {
    _name: 'CSidebarNavTitle',
    _children: ['ADMIN'],
    roles: ['1']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Companies',
    to: '/settings/companies',
    icon: 'cil-cog',
    roles: ['1']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Users',
    to: '/settings/users',
    icon: 'cil-cog',
    roles: ['1']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Sites',
    to: '/settings/sites',
    icon: 'cil-cog',
    roles: ['1']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Rooms',
    to: '/settings/rooms',
    icon: 'cil-cog',
    roles: ['1']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Master Access Cards',
    to: '/settings/cards',
    icon: 'cil-cog',
    roles: ['1']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Log System',
    to: '/settings/log',
    icon: 'cil-cog',
    roles: ['1','2']
  },
]
