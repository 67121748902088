<template>
  <div>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img 
            style="height: 38px; width: 38px;"
            class="c-avatar-img"
            :src="user.user.image ? 'https://dcvisit.lintasarta.net/api/public/avatar/' + user.user.image : 'https://i.pravatar.cc/150?u=' + user.user.id"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="info">
      <strong style="color: #fff;">Account</strong>
    </CDropdownHeader>
    <CDropdownItem @click="profile()">
      <CIcon name="cil-user" /> <strong>{{user.user.name}}</strong>
    </CDropdownItem>
    <CDropdownItem @click="logoutModal = true" v-if="isLogged">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>

  <CModal 
    :show.sync="logoutModal"
    :no-close-on-backdrop="true"
    :centered="true"
    size="sm"
    color="dark"
  >
    Anda yakin ingin keluar dari aplikasi ?
    <template #header>
      <h6 class="modal-title">PERHATIAN</h6>
      <CButtonClose @click="logoutModal = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="logoutModal = false" color="">Batal</CButton>
      <CButton @click="logout()" color="">Keluar</CButton>
    </template>
  </CModal>

  </div>

</template>


<script>
import { 
  mapGetters, 
  mapState 
} from 'vuex'

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      login: localStorage.getItem('user'),
      logoutModal: false
    }
  },
  computed: {
    ...mapGetters(['isLogged']),
    ...mapState(['user'])
  },

  methods: {
    profile(){
      this.$router.push({ path: '/profile' });
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>