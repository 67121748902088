<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://lintasarta.net" target="_blank">Lintasarta</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} PT Aplikanusa Lintasarta.</span>
    </div>
    <div class="mfs-auto">
      <!-- <span class="mr-1" target="_blank">Powered by</span>
      <a target="_blank" href="https://keda-tech.com">KeDa Tech</a> -->
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
